export const queryFragmentSeo = {
  seo: {
    populate: {
      image: '*',
      titles: true,
      descriptions: true,
      keywords: true,
    },
  }
}
